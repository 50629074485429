import React, { Component } from 'react';
import './Banner.css';

class Banner extends Component {
    state = {}
    render() {
        return (
            <>
                <div className="banner">
                    <h3 className="banner__title">Thank you</h3>
                    <div className="banner__text">We will contact you soon.</div>
                    <button className="banner__btn" onClick={this.props.click}>OK</button>
                </div>
            </>
        );
    }
}

export default Banner;