import React from 'react';
import './Burger.css';

const Burger = props => {
    return (
        <div
            className={`${props.isMenuVisible ? 'burger clicked' : 'burger'}`}
            onClick={props.click}>
            <div className="burger__bar"></div>
            <div className="burger__bar"></div>
            <div className="burger__bar"></div>
        </div>
    );
}

export default React.memo(Burger);