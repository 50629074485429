document.addEventListener('scroll', (e) => {
    const logo = document.querySelector('.menu__logo');
    const langs = document.querySelector('.menu__langs');
    const viewportHeight = document.body.offsetHeight;
    const scrollPos = window.scrollY || window.scrollTop || document.getElementsByTagName("html")[0].scrollTop;

    if (scrollPos > viewportHeight / 5) {
        logo.classList.add('visible');
        langs.classList.add('toLeft');

    } else {
        logo.classList.remove('visible')
        langs.classList.remove('toLeft')
    }
});
