import React, { PureComponent } from 'react';
import './Music.css';
import ReactHowler from 'react-howler';

class Music extends PureComponent {
    state = {
        playing: false,
        isPlayerVisible: false,
        isMusicTitleVisible: this.props.MusicTitleVisible
    }

    handleTogglePlayer = () => {
        this.setState(prevState => ({
            isPlayerVisible: !prevState.isPlayerVisible
        }));
    }

    handlePlay = () => {
        this.setState({
            playing: true
        });
    }

    handlePause = () => {
        this.setState({
            playing: false
        });
    }

    handleStop = () => {
        this.player.stop();
        this.setState({
            playing: false,
            isMusicTitleVisible: false
        });
        this.renderSeekPos();
    }

    handleRewind = () => {
        this.player.stop();
        this.renderSeekPos();
    }

    renderSeekPos = () => {
        this.setState({
            seek: this.player.seek()
        })
    }

    handleMusicTitleChange = () => {
        this.setState({ isMusicTitleVisible: true });
        setTimeout(() => {
            if (this.state.isMusicTitleVisible) {
                document.getElementById('musicTitle').classList.add('visible');
            }
        }, 100);
        setTimeout(() => {
            document.getElementById('musicTitle').classList.remove('visible');
            this.setState({ isMusicTitleVisible: false });
        }, 7000);
    }

    render() {
        return (
            <>
                <ReactHowler
                    src={['music1.ogg', 'music1.mp3']}
                    playing={this.state.playing}
                    loop={true}
                    volume={.5}
                    ref={ref => (this.player = ref)}
                />
                <div className='music-basis'></div>
                <div className={`music-panel${!this.state.isPlayerVisible ? ' hidden' : ''}`}>
                    <i onClick={() => { this.handlePlay(); this.handleMusicTitleChange(); }}
                        className="fas fa-play music__extra-icon"></i>
                    <i onClick={this.handlePause}
                        className="fas fa-pause music__extra-icon"></i>
                    <i onClick={this.handleRewind}
                        className="fas fa-undo-alt music__extra-icon"></i>
                    <i onClick={this.handleStop}
                        className="fas fa-stop music__extra-icon"></i>
                </div>
                <i
                    onClick={this.handleTogglePlayer}
                    className={`music-icon fas ${this.state.isPlayerVisible ? 'fa-less-than' : 'fa-music passive'}`}>
                </i>
            </>
        );
    }
}

export default Music;