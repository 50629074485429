import React from 'react';
import './MainMenu.css';

import '../toggleLogo.js';

const MainMenu = props => {
    return (
        <nav className={`${props.isMenuVisible ? 'menu menuVisible' : 'menu'}`}>
            <div className="menu__logo"></div>
            <ul className='menu__list'>
                <a href="#home"><li className='menu__item' onClick={props.click}>{props.lang === 'en' ? 'Home' : 'Start'}</li></a>
                <a href="#about"><li className='menu__item' onClick={props.click}>{props.lang === 'en' ? 'About' : 'O mnie'}</li></a>
                <a href="#technologies"><li className='menu__item' onClick={props.click}>{props.lang === 'en' ? 'Technologies' : 'Technologie'}</li></a>
                <a href="#hire"><li className='menu__item' onClick={props.click}>{props.lang === 'en' ? 'Hire' : 'Współpraca'}</li></a>
                <a href="#contact"><li className='menu__item' onClick={props.click}>{props.lang === 'en' ? 'Contact' : 'Kontakt'}</li></a>
            </ul>
            <div className="menu__langs">
                <p className='menu__lang' onClick={props.clickEng}>eng</p>
                <p className='menu__lang' onClick={props.clickPol}>pol</p>
            </div>
        </nav>
    );
}

export default React.memo(MainMenu);