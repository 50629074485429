import React from 'react';
import './Header.css';

const Header = () => {
    return (
        <header id="home">
            <div className="header">
                <div className="header__logo-wrap">
                    <div className="header__logo"></div>
                </div>
                <div className="header__text-wrap">
                    <h1 className="header__title"><span className='gold'>f</span>rontEpic</h1>
                    <p className="header__subtitle">just awwwesome</p>
                </div>
            </div>

        </header>
    );
}

export default React.memo(Header);