import React from 'react';
import './Video.css';
import myVideo from '../vid/city.mp4';

const Video = props => {
  return (
    <>
      <section className="video-wrap">
        <video className="video__movie" autoPlay loop muted>
          <source src={myVideo} type="video/mp4" />
          {props.lang === 'en' ? 'Your browser does not support the video tag.' : 'Twoja przeglądarka nie pozwala odtworzyć video.'}
        </video>
        <div className="video__layer"></div>
        <div className="video__text-bg"></div>
        <div className="video__border"></div>
        <p className="video__text-one">{props.lang === 'en' ? 'the Web\'s next level' : 'odkryj potęgę Internetu'}</p>
        <p className="video__text-two">
          {props.lang === 'en' ? 'the future is coming' : 'przyszłość nadchodzi'}
        </p>
      </section>
    </>
  );
}

export default React.memo(Video);