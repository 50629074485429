import React, { Component, lazy, Suspense } from 'react';
import './keyframes.css';
import './App.css';
import Burger from './components/Burger';
import Music from './components/Music';
import MainMenu from './components/MainMenu';
import Header from './components/Header';
import Video from './components/Video';
import ArrowUp from './components/ArrowUp';
import Banner from './components/Banner';
import $ from 'jquery';

// Lazy Loading
const About = lazy(() => import('./components/About'));
const Hire = lazy(() => import('./components/Hire'));
const Parallax = lazy(() => import('./components/Parallax'));
const Contact = lazy(() => import('./components/Contact'));
const Footer = lazy(() => import('./components/Footer'));
const MusicTitle = lazy(() => import('./components/MusicTitle'));

class App extends Component {
  state = {
    isMenuVisible: false,
    isMusicTitleVisible: false,
    isGdprVisible: false,
    isFormSubmitted: false,
    lang: 'en'
  }

  componentDidMount = () => {
    $('.arrow-up, .menu__list a').on('click', function (e) {
      if (this.hash !== '') {
        e.preventDefault();
        const hash = this.hash;
        $('html, body').animate({
          scrollTop: $(hash).offset().top
        }, 800);
      }
    });
    window.scrollTo(0, 0);
    // if (this.props.location.search.includes("success=true")) {
    //   this.setState({ isFormSubmitted: true });
    // }
  }

  handleMenuChange = () => {
    this.setState({ isMenuVisible: !this.state.isMenuVisible });
  }

  handleMenuItemClick = () => {
    setTimeout(() => {
      this.setState({ isMenuVisible: false });
    }, 200)
  }

  handleMusicTitleChange = () => {
    this.setState({ isMusicTitleVisible: !this.state.isMusicTitleVisible });
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleGdprSwitch = () => {
    this.setState({ isGdprVisible: !this.state.isGdprVisible });
  }

  handleLanguageChangeToPolish = () => {
    this.setState({ lang: 'pl' });
    setTimeout(() => {
      this.setState({ isMenuVisible: false });
    }, 100)
  }

  handleLanguageChangeToEnglish = () => {
    this.setState({ lang: 'en' });
    setTimeout(() => {
      this.setState({ isMenuVisible: false });
    }, 100)
  }

  handleCloseBanner = () => {
    this.setState({ isFormSubmitted: false });
  }

  render() {
    return (
      <div className="app">
        {this.state.isFormSubmitted && <Banner click={this.handleCloseBanner} />}
        <Music isMusicTitleVisible={this.state.isMusicTitleVisible} />
        <MainMenu isMenuVisible={this.state.isMenuVisible} click={this.handleMenuItemClick} clickPol={this.handleLanguageChangeToPolish} clickEng={this.handleLanguageChangeToEnglish} lang={this.state.lang} />
        <Burger isMenuVisible={this.state.isMenuVisible} click={this.handleMenuChange} />
        <Suspense fallback={<div>Loading...</div>}>
          <ArrowUp />
        </Suspense>
        <Suspense fallback={<div>Loading...</div>}>
          <MusicTitle isMusicTitleVisible={this.state.isMusicTitleVisible} />
        </Suspense>
        <Header />
        <Video lang={this.state.lang} />
        <Suspense fallback={<div>Loading...</div>}>
          <About lang={this.state.lang} />
        </Suspense>
        <Suspense fallback={<div>Loading...</div>}>
          <Parallax />
        </Suspense>
        <Suspense fallback={<div>Loading...</div>}>
          <Hire lang={this.state.lang} />
        </Suspense>
        <Suspense fallback={<div>Loading...</div>}>
          <Contact change={this.handleChange} click={this.handleGdprSwitch} isGdprVisible={this.state.isGdprVisible} lang={this.state.lang} />
          <Footer lang={this.state.lang} />
        </Suspense>
      </div>
    );
  }
}

export default App;
