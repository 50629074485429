import React, { PureComponent } from 'react';
import './ArrowUp.css';

window.addEventListener('scroll', e => {
    const arrowUp = document.querySelector('.go-up');
    const windowHeight = window.outerHeight,
        scrollValue = window.scrollY;
    if (scrollValue > windowHeight / 1.5) arrowUp.classList.add('visible');
    if (scrollValue < windowHeight / 1.5) arrowUp.classList.remove('visible');
});

class ArrowUp extends PureComponent {

    render() {

        return (
            <>
                <a href="#home" className='arrow-up'>
                    <div className="go-up">
                        <i className="fas fa-less-than fa-rotate-90 go-up__arrow-up"></i>
                    </div>
                </a>
            </>
        );
    }

}

export default ArrowUp;